import { Router } from '@gatsbyjs/reach-router'
import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { StudiesContent, StudyDetailsContent } from '../../components/studies'
import { EconsultInvestigatorMeetingModal } from '../../components/studies/Econsult/EconsultInvestigatorMeetingModal'
import {
  EconsultMeetingContextConsumer,
  EconsultMeetingContextProvider
} from '../../components/studies/Econsult/EconsultMeetingContext'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <AuthRoute>
      <EconsultMeetingContextProvider>
        <EconsultMeetingContextConsumer>
          {({ isInvestigatorModalOpened }) => (
            <BasicLayout blockNavigation={isInvestigatorModalOpened}>
              <SessionTimeoutModal blockTimeout={isInvestigatorModalOpened} accountType={AccountType.User} />
              <Router basepath="/:lang/studies">
                <StudiesContent path="/" />
                <StudyDetailsContent path="/:studyId/*" />
              </Router>
              <EconsultInvestigatorMeetingModal />
            </BasicLayout>
          )}
        </EconsultMeetingContextConsumer>
      </EconsultMeetingContextProvider>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Studies</title>
